import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
} from '@reduxjs/toolkit/query/react';
import { Auth } from 'aws-amplify';

import { produce, setAutoFreeze } from 'immer';
import { RootState } from 'store/store';

import {
  IActivitiesByIdStage,
  IConfirmCodeBody,
  IConfirmSchedule,
  ICustomError,
  IProfile,
  IEmail,
  INewPasswordBody,
  InterviewInfo,
  IResendCode,
  IResponse,
  IResponseActivitiesByIdStage,
  IResponseMeet,
  IResponseSchedule,
  IResponseStages,
  IRestorePassword,
  ISendFile,
  ISendImage,
  IStatus,
  IResponseStatus,
  INoCurrentActivity,
  IGetActivity,
  IUserStatus,
  IResponseHelpsOption,
  IHelpsOption,
  IVacantDescription,
  IGetWaitMessageActivity,
  IResponseMaintenance,
  IResponseProfileHubspot,
  ISaveUserBody,
  IResponseAdmissionData,
  ISaveDocumentBody,
  ISaveDocumentInHiringBody,
  IResponseSavedDocuments,
  IDeleteSavedDocumentBody,
  IResponseNewCandidates,
  IUpdatePersonalInfoHiringBody,
  IUpdateOrganizationalInfoHiringBody,
  IUpdateAcademicInfoHiringBody,
  IUpdateContactInfoHiringBody,
  IUpdateCompensationInfoHiringBody,
  ISaveDocumentBeneficiaryBody,
  IDeleteBeneficiaryBody,
  ISaveBeneficiaryBody,
  ISendDocumentsBody,
  IStatusBody,
  IUpdateBankingInformation,
  IWelcomeKitResponse,
} from 'utils/interfaces';
import { kitImplementProps } from 'utils/interfaces/forms/chooseKit';

import { IAdminPanelForm } from 'components/organisms/AdminPanelForm/AdminPanelForm.schema';
import { IUserConfirmData } from 'components/organisms/ConfirmDataTabForm/ConfirmDataTabForm';
import { IUploadDocumentFormSchemaColombia } from 'components/organisms/NewUploadDocumentsColombia';
import { IUploadDocumentFormSchemaInternship } from 'components/organisms/UploadDocumentsInternship';
import {
  IAdditionalInformation,
  UploadDocumentFormSchema,
} from 'components/organisms/UploadDocumentsTabForm/UploadDocumentsTabForm';

export const talentoApi = createApi({
  reducerPath: 'talentoApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_TALENTO_URL,
    prepareHeaders: async (headers, { getState }) => {
      // Set AuthToken
      try {
        const data = await Auth.currentSession();
        const authToken = data.getAccessToken().getJwtToken();
        headers.set('Authorization', authToken);
      } catch (error) {}

      // Set languageCode
      const languageCode =
        (getState() as RootState).translations.languageCode || 'es';
      headers.set('Accept-language', `${languageCode}`);

      return headers;
    },
  }) as BaseQueryFn<string | FetchArgs, unknown, ICustomError, {}>,

  endpoints: (builder) => ({
    restorePassword: builder.mutation<IResponse, IRestorePassword>({
      query({ email }) {
        return {
          url: '/security/authentication/send-code',
          method: 'POST',
          body: { email },
        };
      },
    }),
    submitConfirmData: builder.mutation<
      { message: string; status?: number },
      { email: string; idVacant: number }
    >({
      query({ email, idVacant }) {
        return {
          url: `/hiring/candidates/confirm-data?email=${email}&idVacant=${idVacant}`,
          method: 'PUT',
        };
      },
    }),
    updateAllUserData: builder.mutation<IUserConfirmData, IUserConfirmData>({
      query: (data) => {
        return {
          url: '/hiring/candidates/updateData',
          method: 'POST',
          body: data,
        };
      },
    }),
    updateUserData: builder.mutation<IUserConfirmData, IUserConfirmData>({
      query: (data) => {
        return {
          url: '/hiring/candidates/updateData/user',
          method: 'PUT',
          body: data,
        };
      },
    }),
    updateAcademicInformation: builder.mutation<
      IUserConfirmData,
      {
        id: number;
        academicInformation: IUserConfirmData['academicInformation'];
      }
    >({
      query: (data) => {
        return {
          url: '/hiring/candidates/updateData/academicInformation',
          method: 'PUT',
          body: data,
        };
      },
    }),
    updateEmergencyContactInformation: builder.mutation<
      IUserConfirmData,
      {
        id: number;
        emergencyContactInformation: IUserConfirmData['emergencyContactInformation'];
      }
    >({
      query: (data) => {
        return {
          url: '/hiring/candidates/updateData/emergencyContactInformation',
          method: 'PUT',
          body: data,
        };
      },
    }),
    confirmCode: builder.mutation<IResponse, IConfirmCodeBody>({
      query({ email, code }) {
        return {
          url: '/security/authentication/verify-code',
          method: 'POST',
          body: {
            email,
            code,
          },
        };
      },
    }),
    newPassword: builder.mutation<IResponse, INewPasswordBody>({
      query: ({ email, newPassword, confirmedPassword }) => ({
        url: '/security/authentication/restore-password',
        method: 'POST',
        body: {
          email,
          newPassword,
          confirmedPassword,
        },
      }),
    }),
    resendCode: builder.mutation<IResponse, IResendCode>({
      query: ({ email }) => ({
        url: '/security/authentication/resend-code',
        method: 'POST',
        body: { email },
      }),
    }),
    getStages: builder.query<IResponseStages, IEmail>({
      query({ email }) {
        return {
          url: `/tracking/contacts/vacant?email=${email}`,
        };
      },
    }),
    getMaintenanceFromAdmin: builder.query<IResponseMaintenance, any>({
      query() {
        return {
          url: `/admin/parameter/maintenance`,
        };
      },
    }),
    getMaintenanceFromStage: builder.query<IResponseMaintenance, any>({
      query() {
        return {
          url: `/stages/parameter/id/19`,
        };
      },
    }),
    getActivitiesByIdStage: builder.query<
      IResponseActivitiesByIdStage,
      IActivitiesByIdStage
    >({
      query({ idStage, idVacant, email }) {
        return {
          url: `/stages/content?idActivity=${idStage}&idVacant=${idVacant}&email=${email}`,
        };
      },
    }),
    getActivityStatus: builder.query({
      query({ idStage, idVacant, email }) {
        return {
          url: `/stages/status?idActivity=${idStage}&idVacant=${idVacant}&email=${email}`,
        };
      },
    }),

    changeStatus: builder.mutation<IResponse, IStatus>({
      query: ({ idStage, idVacant, email, status, activityName }) => ({
        url: `/stages/change-status?idActivity=${idStage}&idVacant=${idVacant}&email=${email}&status=${status}&activityName=${activityName}`,
        method: 'PUT',
      }),
    }),

    getListCalendar: builder.query({
      query({ idActivity, email }) {
        return {
          url: `/stages/list-calendar?idActivity=${idActivity}&email=${email}`,
        };
      },
    }),

    scheduleMeeting: builder.mutation({
      query: ({ idEvent, idCalendar, email, idActivity, candidateFullName }) => ({
        url: `/stages/schedule-meeting`,
        method: 'POST',
        body: {
          idEvent, idCalendar, invitedEmail: email, idActivity, candidateFullName
        }
      }),
    }),

    confirmSchedule: builder.mutation<IResponseMeet, IConfirmSchedule>({
      query: ({ idActivity, email, startUnixDate }) => ({
        url: `/stages/schedules/${startUnixDate}?email=${email}&idActivity=${idActivity}`,
        method: 'POST',
      }),
    }),
    getSchedule: builder.query<IResponseSchedule, IEmail>({
      query({ email, idActivity }) {
        return {
          url: `/stages/schedules?email=${email}&idActivity=${idActivity}`,
        };
      },
    }),
    sendFile: builder.mutation<IResponse, ISendFile>({
      query: ({
        data,
        email,
        idStage,
        idVacant,
        idActivity,
        activityName,
      }) => ({
        url: `/tracking/upload/file?email=${email}&idStage=${idStage}&idVacant=${idVacant}&idActivity=${idActivity}&activityName=${activityName}`,
        method: 'POST',
        body: data,
      }),
    }),
    sendImage: builder.mutation<IResponse, ISendImage>({
      query: (data) => ({
        url: `/stages/help`,
        method: 'POST',
        body: data,
      }),
    }),
    // se obtiene los datos de la entrevista
    getEnterviewData: builder.query<InterviewInfo, IEmail>({
      query({ idActivity, email }) {
        return {
          url: `/stages/meets/${email}?idActivity=${idActivity}`,
        };
      },
    }),
    getProfileData: builder.query<IProfile, { email: string }>({
      query: ({ email }) => `/security/users?email=${email}`,
      transformResponse: (response: { data: IProfile }) => response.data,
    }),
    getNoCurrentActivity: builder.query<INoCurrentActivity, IGetActivity>({
      query: ({ idStage, idVacant, email }) =>
        `stages/waiting-message?idStage=${idStage}&idVacant=${idVacant}&email=${email}`,
      transformResponse: (response: { data: INoCurrentActivity }) =>
        response.data,
    }),
    updateProfileData: builder.mutation<IResponseStatus, IProfile>({
      query: (profileData) => ({
        url: `/security/users`,
        method: 'PUT',
        body: profileData,
      }),
    }),
    getUserStatus: builder.query<IUserStatus, { email: string }>({
      query: ({ email }) => {
        return {
          url: `/stages/get-job-application-status?email=${email}`,
        };
      },
    }),
    getListHelpOptions: builder.query<IHelpsOption, any>({
      query() {
        return {
          url: `/stages/list-help-options`,
        };
      },
    }),
    postListHelpOptions: builder.mutation<IResponse, IResponseHelpsOption>({
      query: ({ option, email, message, id }) => ({
        url: `/stages/help`,
        method: 'POST',
        body: {
          option,
          email,
          message,
          id,
        },
      }),
    }),
    getVacantDescription: builder.query<IVacantDescription, { email: string }>({
      query: ({ email }) => {
        return {
          url: `/tracking/contacts/vacant/description?email=${email}`,
        };
      },
    }),
    getApplicationStatus: builder.query<any, { email: string }>({
      query: ({ email }) => {
        return {
          url: `/notification/get-notification-status/email?email=${email}`,
        };
      },
    }),
    getApplicationStatusbusinessDayATR: builder.query<any, { email: string }>({
      query: ({ email }) => {
        return {
          url: `/notification/get-notification-status/businessDayATR/email?email=${email}`,
        };
      },
    }),
    extendTerm: builder.mutation<IResponseStatus, { email: string }>({
      query: ({ email }) => ({
        url: `/stages/get-notification-status/email`,
        method: 'POST',
        body: {
          email,
          platform: 'WEB',
        },
      }),
    }),
    getWaitingMessageForActivity: builder.query<
      INoCurrentActivity,
      IGetWaitMessageActivity
    >({
      query: ({ idActivity, idVacant, email }) => ({
        url: `/stages/waiting-message-activity?idActivity=${idActivity}&idVacant=${idVacant}&email=${email}`,
      }),
    }),
    getPreStageUser: builder.query<any, any>({
      query: (email) => ({
        url: `/tracking/contacts/vacant?email=${email}`,
      }),
    }),
    getProfileDataFromHubspot: builder.query<
      IResponseProfileHubspot,
      { email: string }
    >({
      query: ({ email }) => ({
        url: `/hubspot/contacts/search?email=${email}`,
      }),
    }),
    saveUser: builder.mutation<{}, ISaveUserBody>({
      query: (body) => ({
        url: `/hiring/hiring/save-user`,
        method: 'POST',
        body,
      }),
    }),
    getAdmissionDate: builder.query<IResponseAdmissionData, { email: string }>({
      query: ({ email }) => ({
        url: `/hubspot/business/admission-date?email=${email}`,
      }),
    }),
    saveDocument: builder.mutation<{ url: string }, ISaveDocumentBody>({
      query: (body) => ({
        url: `/files/files`,
        method: 'POST',
        body,
      }),
    }),
    saveDocumentInHiring: builder.mutation<{}, ISaveDocumentInHiringBody>({
      query: (body) => ({
        url: `/hiring/documents`,
        method: 'POST',
        body,
      }),
    }),
    saveDocumentBeneficiaryInHiring: builder.mutation<
      { [key: string]: string },
      ISaveDocumentBeneficiaryBody
    >({
      query: (body) => ({
        url: `/files/files/beneficiary`,
        method: 'POST',
        body,
      }),
    }),
    saveBeneficiaryInHiring: builder.mutation<{}, ISaveBeneficiaryBody>({
      query: (body) => ({
        url: `/hiring/beneficiary`,
        method: 'POST',
        body,
      }),
    }),
    sendDocumentsInHiring: builder.mutation<IResponse, ISendDocumentsBody>({
      query: ({ email, idVacant, fullName }) => ({
        url: `/hiring/documents/send?email=${email}&idVacant=${idVacant}`,
        method: 'POST',
      }),
    }),
    getSavedDocuments: builder.query<
      IResponseSavedDocuments,
      { email: string }
    >({
      query: ({ email }) => ({
        url: `/hiring/documents?email=${email}`,
      }),
      keepUnusedDataFor: 0,
    }),
    deleteSavedDocument: builder.mutation<{}, IDeleteSavedDocumentBody>({
      query: (body) => ({
        url: `/hiring/documents`,
        method: 'DELETE',
        body,
      }),
    }),
    getNewCandidates: builder.query<IResponseNewCandidates, { status: number }>(
      {
        query: ({ status }) => ({
          url: `/hiring/candidates/status?status=${status}`,
        }),
      }
    ),
    getInfoCandidate: builder.query({
      query: ({ email }) => ({
        url: `/hiring/candidates?email=${email}`,
      }),
      transformResponse: (response: { data: any }) => {
        setAutoFreeze(false);
        // Deeply change every null value into an empty string
        function changeNullToEmptyString(obj: any) {
          for (let key in obj) {
            if (obj[key] === null || obj[key] === undefined) {
              obj[key] = '';
            } else if (
              typeof obj[key] === 'object' &&
              key !== 'growInformation'
            ) {
              changeNullToEmptyString(obj[key]);
            }
          }
        }

        changeNullToEmptyString(response.data);

        let newData: any = produce(response.data, (draft: any) => {
          draft.position = draft.organizationalInformation.position ?? '';
          draft.contractType =
            draft.organizationalInformation.contractType ?? '';
        });

        newData = produce(newData, (draft: IAdminPanelForm) => {
          if (draft.growInformation.length < 2) {
            draft.growInformation.push({
              chapter: null,
              technology: null,
              id: 0,
              idChapterSap: null,
              idSpecialtySap: null,
              idTechnologySap: null,
              idTechnicalLevelSap: null,
              technicaLevel: null,
              position: 0,
              specialty: null,
            });
          }
        });

        return { data: newData };
      },
    }),
    updatePersonalInfoHiring: builder.mutation<
      {},
      IUpdatePersonalInfoHiringBody
    >({
      query: (body) => ({
        url: `/hiring/candidates/personal`,
        method: 'PUT',
        body,
      }),
    }),
    updateOrganizationalInfoHiring: builder.mutation<
      { data: IAdminPanelForm },
      Omit<IUpdateOrganizationalInfoHiringBody, 'updateGrowRequest'> & {
        updateGrowRequest: Omit<IAdminPanelForm['growInformation'][0], 'id'>[];
      }
    >({
      query: (body) => ({
        url: `/hiring/candidates/organizational`,
        method: 'PUT',
        body,
      }),
    }),
    updateAcademicInfoHiring: builder.mutation<
      {},
      IUpdateAcademicInfoHiringBody
    >({
      query: (body) => ({
        url: `/hiring/candidates/academic`,
        method: 'PUT',
        body,
      }),
    }),

    updateContactInfoHiring: builder.mutation<{}, IUpdateContactInfoHiringBody>(
      {
        query: (body) => ({
          url: `/hiring/candidates/contact`,
          method: 'PUT',
          body,
        }),
      }
    ),
    updateCompensationInfoHiring: builder.mutation<
      { data: IAdminPanelForm },
      IUpdateCompensationInfoHiringBody
    >({
      query: (body) => ({
        url: `/hiring/candidates/compensation`,
        method: 'PUT',
        body,
      }),
    }),
    updateStatusHiring: builder.mutation<{}, IStatusBody>({
      query: (body) => ({
        url: `/hiring/candidates/email?email=${body.email}&qualifierName=${body.qualifierName}`,
        method: 'POST',
        body,
      }),
    }),
    updateStatusSapHiring: builder.mutation<{}, IStatusBody>({
      query: (body) => ({
        url: `/hiring/candidates/sap?email=${body.email}`,
        method: 'POST',
        body,
      }),
    }),
    rehireSap: builder.mutation<{}, { email: string; externalCode: string }>({
      query: (body) => ({
        url: `/hiring/candidates/sap?email=${body.email}&personIdExternal=${body.externalCode}`,
        method: 'PUT',
      }),
    }),
    deleteSaveBeneficiary: builder.mutation<{}, IDeleteBeneficiaryBody>({
      query: (body) => ({
        url: `/hiring/beneficiary`,
        method: 'DELETE',
        body,
      }),
    }),
    getListRelationships: builder.query({
      query: ({ email }) => ({
        url: `/hiring/relationships?email=${email}`,
      }),
    }),
    getListBeneficiary: builder.query<{ data: any[] }, { email: string }>({
      query: ({ email }) => ({
        url: `/hiring/beneficiary?email=${email}`,
      }),
    }),
    getListDocumentType: builder.query({
      query: ({ country }) => ({
        url: `/hiring/candidates/document-type?country=${country}`,
      }),
    }),
    updateBankingInformation: builder.mutation<
      { message: string; status?: number },
      IUpdateBankingInformation
    >({
      query: (body) => ({
        url: `/hiring/candidates/updateUserBankInformation`,
        method: 'POST',
        body,
      }),
    }),
    uploadWorkInformation: builder.mutation<
      {
        data: UploadDocumentFormSchema['workInformation'];
      },
      {
        email: string;
      } & UploadDocumentFormSchema['workInformation']
    >({
      query: (body) => ({
        url: '/hiring/candidates/work-information',
        method: 'PUT',
        body,
      }),
    }),
    afpCatalog: builder.query<{ data: { id: number; name: string }[] }, any>({
      query: () => ({
        url: '/hiring/candidates/afp-catalogue?countryIso=PER',
      }),
    }),
    pensionCatalog: builder.query<
      { data: { id: number; name: string }[] },
      any
    >({
      query: () => ({
        url: '/hiring/candidates/pension-catalogue?countryIso=PER',
      }),
    }),
    pensionCatalogCol: builder.query<
      { data: { id: number; name: string }[] },
      any
    >({
      query: () => ({
        url: '/hiring/candidates/pension-catalogue?countryIso=COL',
      }),
    }),
    bankData: builder.query<{ data: { id: number; name: string }[] }, any>({
      query: () => ({
        url: '/hiring/candidates/bank-catalogue?countryIso=PER',
      }),
    }),
    epsData: builder.query<{ data: { id: number; name: string }[] }, any>({
      query: () => ({
        url: '/hiring/candidates/eps-catalogue?countryIso=COL',
      }),
    }),
    cesantiasData: builder.query<{ data: { id: number; name: string }[] }, any>(
      {
        query: () => ({
          url: '/hiring/candidates/severance-catalogue?countryIso=COL',
        }),
      }
    ),
    colombiaBankData: builder.query<
      { data: { id: number; name: string }[] },
      any
    >({
      query: () => ({
        url: '/hiring/candidates/bank-catalogue?countryIso=COL',
      }),
    }),
    GetListRelations: builder.query<
      { data: { id: number; name: string }[] },
      any
    >({
      query: () => ({
        url: '/hiring/candidates/relationship-catalogue?countryIso=PER',
      }),
    }),
    GetListRelationsCol: builder.query<
      { data: { id: number; name: string }[] },
      any
    >({
      query: () => ({
        url: '/hiring/candidates/relationship-catalogue?countryIso=COL',
      }),
    }),
    declarationDocumentUrl: builder.query<{ url: string }, any>({
      query: () => ({
        url: '/hiring/documents/blueprints?name=Declaracion_jurada_seguro_de_vida&countryIso=PER',
      }),
    }),
    updatePassportProperty: builder.mutation<
      any,
      { hasPassport: boolean | null; email: string }
    >({
      query: (body) => ({
        url: '/hiring/candidates/updatePassportProperty',
        method: 'PUT',
        body,
      }),
    }),
    confirmDataResume: builder.mutation<
      { message: string; status?: number },
      { email: string; idVacant: number }
    >({
      query({ email, idVacant }) {
        return {
          url: `/hiring/candidates/confirmDocumentUpload?email=${email}&idVacant=${idVacant}`,
          method: 'PATCH',
        };
      },
    }),
    updateInsuranceRadio: builder.mutation<
      {
        message: string;
        status?: number;
      },
      {
        email: string;
      } & UploadDocumentFormSchema['beneficiaryInformation']
    >({
      query: (body) => ({
        url: '/hiring/candidates/updateBeneficiariesChecks',
        method: 'PUT',
        body,
      }),
    }),
    updateInsuranceRadioColombia: builder.mutation<
      {
        message: string;
        status?: number;
      },
      {
        email: string;
      } & IUploadDocumentFormSchemaColombia['beneficiaryInformation']
    >({
      query: (body) => ({
        url: '/hiring/candidates/updateBeneficiariesChecks',
        method: 'PUT',
        body,
      }),
    }),
    saveBankInformation: builder.mutation<
      { message: string; status?: number },
      {
        email: string;
        bankInformation: Exclude<
          IUploadDocumentFormSchemaColombia['bankInformation'],
          'hasBank' | 'wantsBancolombiaAcc'
        >;
      } & Pick<
        IUploadDocumentFormSchemaColombia,
        'hasBank' | 'wantsBancolombiaAcc'
      >
    >({
      query: (body) => ({
        url: '/hiring/candidates/updateUserBankInformation',
        method: 'POST',
        body,
      }),
    }),
    saveAffiliateInformation: builder.mutation<
      { message: string; status?: number },
      {
        email: string;
      } & IUploadDocumentFormSchemaColombia['affiliateInformationResponse']
    >({
      query: (body) => ({
        url: '/hiring/candidates/updateUserAffiliateInformation',
        method: 'POST',
        body,
      }),
    }),
    saveAffiliateInformationInternship: builder.mutation<
      { message: string; status?: number },
      {
        email: string;
      } & IUploadDocumentFormSchemaInternship['affiliateInformationResponse']
    >({
      query: (body) => ({
        url: '/hiring/candidates/updateUserAffiliateInformation',
        method: 'POST',
        body,
      }),
    }),
    getAdditionalInformationColombia: builder.query<
      { data: IUploadDocumentFormSchemaInternship },
      { email: string }
    >({
      query: ({ email }) => ({
        url: `/hiring/candidates/getUserAdditionalInformation?email=${email}`,
      }),
      transformResponse: (response: {
        data: IUploadDocumentFormSchemaInternship;
      }) => {
        let newResponse = response.data;

        newResponse = produce(newResponse, (draft) => {
          if (draft.loadedDocuments.cert_eps) {
            draft.affiliateInformationResponse.epsCertificate =
              draft.loadedDocuments.cert_eps;
          }
          
          if (draft.loadedDocuments.cert_pension) {
            draft.affiliateInformationResponse.pensionCertificate = 
            draft.loadedDocuments.cert_pension;
          }
          
          if (draft.loadedDocuments.cert_cesantias) {
            draft.affiliateInformationResponse.cesantiasCertificate =
              draft.loadedDocuments.cert_cesantias;
          }

          if (draft.loadedDocuments.cert_bancario) {
            draft.bankInformation.cert_account =
              draft.loadedDocuments.cert_bancario;
          }

          if (draft.hasBank !== undefined) {
            draft.bankInformation.hasBank = draft.hasBank;
          }

          if (draft.wantsBancolombiaAcc !== undefined) {
            draft.bankInformation.wantsBancolombiaAcc =
              draft.wantsBancolombiaAcc;
          }

          if (draft.affiliateInformationResponse === null) {
            draft.affiliateInformationResponse = {
              epsCertificate: undefined as any,
              hasEps: undefined as any,
              wantedEpsAffiliate: undefined as any,
              hasPension: undefined as any,
              pensionCertificate: undefined as any,
              wantedPensionFund: undefined as any,
              hasSeveranceFund: undefined as any,
              cesantiasCertificate: undefined as any,
              wantedSeveranceFund: undefined as any,
            };
          }
        });

        return { data: newResponse };
      },
    }),
    getAdditionalInformation: builder.query<
      { data: IAdditionalInformation },
      { email: string }
    >({
      query: ({ email }) => ({
        url: `/hiring/candidates/getUserAdditionalInformation?email=${email}`,
      }),
      transformResponse: (response: { data: IAdditionalInformation }) => {
        const DATE_START_INDEX = 0;
        const DATE_END_INDEX = 10;

        let newResponse = response.data;

        newResponse = produce(newResponse, (draft) => {
          const defaultValue = [
            {
              id: 0,
              currency: 'PER',
              company: '',
              position: '',
              salary: '',
              startDate: '',
              endDate: '',
            },
          ];

          draft.workInformation.workHistory =
            draft.workInformation.workHistory.length === 0
              ? defaultValue
              : draft.workInformation.workHistory;
        });

        newResponse = produce(newResponse, (draft) => {
          for (let history of draft.workInformation.workHistory) {
            const currentDate = '';
            history.startDate = history.startDate
              ? new Date(history.startDate)
                  .toISOString()
                  .slice(DATE_START_INDEX, DATE_END_INDEX)
              : currentDate;
            history.endDate = history.endDate
              ? new Date(history.endDate)
                  .toISOString()
                  .slice(DATE_START_INDEX, DATE_END_INDEX)
              : currentDate;
          }
        });

        newResponse = produce(newResponse, (draft) => {
          if (!draft.workInformation.pensionInformation) {
            draft.workInformation.pensionInformation = {
              isAffiliate: undefined as any,
              isPensioner: undefined as any,
              pensionType: '',
              pensionEntity: '',
            };
          }
        });

        newResponse = produce(newResponse, (draft) => {
          if (draft.loadedDocuments.cert_renta) {
            draft.workInformation.cert_renta = draft.loadedDocuments.cert_renta;
          }
        });

        return { data: newResponse };
      },
    }),
    getKitImplements: builder.query({
      query: () => ({
        url: `/hiring/welcomeKit/kitImplement`,
      }),
      transformResponse: (response: { data: kitImplementProps[] }) => {
        return response.data;
      },
    }),
    selectKitImplement: builder.mutation<
      {},
      { email: string; kitImplementId: number }
    >({
      query: ({ email, kitImplementId }) => ({
        url: `/hiring/welcomeKit/selectedKitImplement?email=${email}&kitImplementId=${kitImplementId}`,
        method: 'POST',
      }),
    }),
    deleteKitImplement: builder.mutation<
      {},
      { email: string; kitImplementId: number }
    >({
      query: ({ email, kitImplementId }) => ({
        url: `/hiring/welcomeKit/selectedKitImplement?email=${email}&kitImplementId=${kitImplementId}`,
        method: 'DELETE',
      }),
    }),
    updateComputerType: builder.mutation<
      {},
      { computerPragma: boolean; computerOwn: boolean; email: string }
    >({
      query: ({ computerPragma, computerOwn, email }) => ({
        url: `/hiring/welcomeKit?email=${email}`,
        method: 'PUT',
        body: { computerPragma, computerOwn },
      }),
    }),
    confirmChoseeResume: builder.mutation({
      query: ({ email, idVacant }) => ({
        url: `/hiring/welcomeKit/confirm?email=${email}&idVacant=${idVacant}`,
        method: 'PUT',
        body: { email, idVacant },
      }),
    }),
    getListNotifications: builder.query<{
      data: {
        id: number;
        notificationId: number;
        title: string;
        description: string;
        path: string;
        notificationStatus: string;
        notificationType?: string
      }[]
    }, { email: string }>
      ({
        query({ email }) {
          return {
            url: `/notification/real-time/${email}`,
            method: 'GET',
          };
        },
      }),
    deleteNotificationsFirebase: builder.mutation({
      query: (email) => ({
        url: `/notification/real-time/${email}`,
        method: 'DELETE'
      }),
    }),
    getWelcomeKit: builder.query<{ data: IWelcomeKitResponse }, { email: string }>({
      query: ({ email }) => ({
        url: `/hiring/welcomeKit?email=${email}`,
      }),
    }),
    updateStatusNotifications: builder.mutation({
      query: ({ idNotificacion, status }) => ({
        url: `/notification/real-time/${idNotificacion}`,
        method: 'PATCH',
        body: { status },
      }),
    }),
  }),
});



export const {
  useGetListNotificationsQuery,
  useGetWelcomeKitQuery,
  useUpdateComputerTypeMutation,
  useDeleteKitImplementMutation,
  useSelectKitImplementMutation,
  useGetKitImplementsQuery,
  useUploadWorkInformationMutation,
  useDeclarationDocumentUrlQuery,
  useGetAdditionalInformationQuery,
  useUpdatePassportPropertyMutation,
  useAfpCatalogQuery,
  usePensionCatalogQuery,
  usePensionCatalogColQuery,
  useBankDataQuery,
  useEpsDataQuery,
  useCesantiasDataQuery,
  useColombiaBankDataQuery,
  useUpdateBankingInformationMutation,
  useRestorePasswordMutation,
  useConfirmCodeMutation,
  useNewPasswordMutation,
  useResendCodeMutation,
  useGetStagesQuery,
  useGetActivitiesByIdStageQuery,
  useGetActivityStatusQuery,
  useChangeStatusMutation,
  useGetListCalendarQuery,
  useScheduleMeetingMutation,
  useConfirmScheduleMutation,
  useGetScheduleQuery,
  useSendFileMutation,
  useSendImageMutation,
  useGetEnterviewDataQuery,
  useGetProfileDataQuery,
  useUpdateProfileDataMutation,
  useGetNoCurrentActivityQuery,
  useGetUserStatusQuery,
  useGetListHelpOptionsQuery,
  usePostListHelpOptionsMutation,
  useGetVacantDescriptionQuery,
  useGetApplicationStatusQuery,
  useExtendTermMutation,
  useGetWaitingMessageForActivityQuery,
  useGetMaintenanceFromAdminQuery,
  useGetMaintenanceFromStageQuery,
  useGetApplicationStatusbusinessDayATRQuery,
  useGetPreStageUserQuery,
  useGetProfileDataFromHubspotQuery,
  useSaveUserMutation,
  useGetAdmissionDateQuery,
  useSaveDocumentMutation,
  useSaveDocumentInHiringMutation,
  useGetSavedDocumentsQuery,
  useDeleteSavedDocumentMutation,
  useGetNewCandidatesQuery,
  useGetInfoCandidateQuery,
  useUpdatePersonalInfoHiringMutation,
  useUpdateOrganizationalInfoHiringMutation,
  useUpdateAcademicInfoHiringMutation,
  useUpdateContactInfoHiringMutation,
  useUpdateCompensationInfoHiringMutation,
  useGetListRelationshipsQuery,
  useGetListBeneficiaryQuery,
  useSaveDocumentBeneficiaryInHiringMutation,
  useDeleteSaveBeneficiaryMutation,
  useSaveBeneficiaryInHiringMutation,
  useSendDocumentsInHiringMutation,
  useUpdateStatusHiringMutation,
  useUpdateStatusSapHiringMutation,
  useGetListDocumentTypeQuery,
  useUpdateAcademicInformationMutation,
  useUpdateEmergencyContactInformationMutation,
  useUpdateUserDataMutation,
  useSubmitConfirmDataMutation,
  useUpdateAllUserDataMutation,
  useRehireSapMutation,
  useUpdateInsuranceRadioMutation,
  useGetListRelationsQuery,
  useGetListRelationsColQuery,
  useConfirmDataResumeMutation,
  useGetAdditionalInformationColombiaQuery,
  useSaveBankInformationMutation,
  useSaveAffiliateInformationMutation,
  useUpdateInsuranceRadioColombiaMutation,
  useSaveAffiliateInformationInternshipMutation,
  useConfirmChoseeResumeMutation,
  useDeleteNotificationsFirebaseMutation,
  useUpdateStatusNotificationsMutation
} = talentoApi;
